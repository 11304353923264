import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { LeftHeaderContentBuilderComponent, RightHeaderContentBuilderComponent, BannerBelowHeaderBuilderComponent, ThreeBannersBelowHeaderBuilderComponent } from '@tvlgiao/bc-supermarket-builder-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "header"
    }}>{`Header`}</h1>
    <h2 {...{
      "id": "header-styles"
    }}>{`Header Styles`}</h2>
    <p>{`Theme supports 3 different header styles:`}</p>
    <ul>
      <li parentName="ul">{`Logo at left`}</li>
      <li parentName="ul">{`Logo at center`}</li>
      <li parentName="ul">{`Logo at right`}</li>
    </ul>
    <p><img alt="Header Logo at left" src={require("../img/home1-header-left.png")} /></p>
    <p><img alt="Header Logo at center" src={require("../img/home1-header-center.png")} /></p>
    <p><img alt="Header Logo at right" src={require("../img/home1-header-right.png")} /></p>
    <p>{`To configure it, open the `}<strong parentName="p">{`Theme Editor`}</strong>{`, scroll down to `}<strong parentName="p">{`Logo`}</strong>{` section, click to expand Logo options, choose `}<strong parentName="p">{`Logo position`}</strong>{` as you wish.`}</p>
    <p><img alt="Change logo position" src={require("../img/change-logo-position.png")} /></p>
    <h2 {...{
      "id": "welcome-text"
    }}>{`Welcome Text`}</h2>
    <p><img alt="Welcome text" src={require("../img/home1-welcome-text.png")} /></p>
    <p>{`The welcome text can be showed in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Header`}</strong>{` > tick on `}<strong parentName="p">{`Show welcome text`}</strong></p>
    <p><img alt="Toggle show/hide welcome text" src={require("../img/theme-editor-show-welcome-text.png")} /></p>
    <p>{`You can edit the text in the language file `}<inlineCode parentName="p">{`lang/en.json`}</inlineCode>{` in `}<strong parentName="p">{`Edit Theme Files`}</strong>{`:`}</p>
    <p><img alt="Edit language for welcome text" src={require("../img/edit-language-welcome-text.png")} /></p>
    <h2 {...{
      "id": "phone-number--store-address"
    }}>{`Phone Number & Store Address`}</h2>
    <p><img alt="Header info text" src={require("../img/home1-infobar.png")} /></p>
    <p>{`Your store address and phone number are pulled out from `}<strong parentName="p">{`Store Profile`}</strong>{`. You can edit it in `}<strong parentName="p">{`Store Setup`}</strong>{` > `}<strong parentName="p">{`Store Profile`}</strong>{`:`}</p>
    <p><img alt="Edit Store Address and Phone" src={require("../img/edit-store-address-phone.png")} /></p>
    <h2 {...{
      "id": "promotion-content-on-the-header-left"
    }}>{`Promotion content on the header left`}</h2>
    <p><img alt="Promotion content on top left of header" src={require("../img/promo-text-on-top-left-header.png")} /></p>
    <p>{`To display the custom text at this position, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Header`}</strong>{`, tick on `}<strong parentName="p">{`Show custom content after welcome text`}</strong>{`, save & publish:`}</p>
    <p><img alt="Theme editor - show custom content after welcome text" src={require("../img/theme-editor-show-promo-text-on-top-left-header.png")} /></p>
    <p>{`Then create a new banner content in `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` as below screenshot:`}</p>
    <p><img alt="Edit banner Promo Text on top left of header" src={require("../img/edit-banner-promo-text-on-top-left-header.png")} /></p>
    <p><strong parentName="p">{`Banner Content`}</strong>{`: Click the `}<strong parentName="p">{`HTML`}</strong>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    {
      /* <SupermarketTopHeaderBanner01 /> */
    }
    <LeftHeaderContentBuilderComponent mdxType="LeftHeaderContentBuilderComponent" />
    <p><em parentName="p"><strong parentName="em">{`Note:`}</strong>{` DO NOT remove `}<inlineCode parentName="em">{`emthemesModezBannersBlockTopHeaderBanner01`}</inlineCode>{`.`}</em></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{`: `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "custom-links-on-the-header-right"
    }}>{`Custom Links on the header right`}</h2>
    <p><img alt="Custom Links on Top Right of Header" src={require("../img/custom-links-on-top-right-header.png")} /></p>
    <p>{`To display the custom links at this position, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Header`}</strong>{` > `}<strong parentName="p">{`Top Bar`}</strong>{`, tick on `}<strong parentName="p">{`Show extra links`}</strong>{`, save & publish:`}</p>
    <p><img alt="theme-editor-show-extra-links-topbar" src={require("../img/theme-editor-show-extra-links-topbar.png")} /></p>
    <p>{`Then create a new banner content in `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` as showing below:`}</p>
    <p><img alt="Edit banner custom links on top right of header" src={require("../img/edit-banner-topbar-custom-links.png")} /></p>
    <p><strong parentName="p">{`Banner Content`}</strong>{`: Click to the `}<strong parentName="p">{`HTML`}</strong>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    {
      /* <SupermarketTopHeaderBanner02 /> */
    }
    <RightHeaderContentBuilderComponent mdxType="RightHeaderContentBuilderComponent" />
    <p><em parentName="p"><strong parentName="em">{`Note:`}</strong>{` DO NOT remove `}<inlineCode parentName="em">{`emthemesModezBannersBlockTopHeaderBanner02`}</inlineCode>{`.`}</em></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{`: `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "social-media-icons"
    }}>{`Social Media Icons`}</h2>
    <p><img alt="Social media icons on header" src={require("../img/home1-header-social-icons.png")} /></p>
    <p>{`The social media icons group can be turned on/off in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Social Media Icons`}</strong>{` > `}<strong parentName="p">{`Placement`}</strong>{` > `}<strong parentName="p">{`Top/Right`}</strong>{`:`}</p>
    <p><img alt="Configure social icons on header" src={require("../img/theme-editor-social-icons-top.png")} /></p>
    <p>{`To configure your social media links, go to `}<strong parentName="p">{`Storefront Design`}</strong>{` > `}<strong parentName="p">{`Design Options`}</strong>{` > `}<strong parentName="p">{`Social Media`}</strong>{` section:`}</p>
    <p><img alt="Edit social media links" src={require("../img/edit-social-media-icons.png")} /></p>
    <h2 {...{
      "id": "colors-customization"
    }}>{`Colors Customization`}</h2>
    <p>{`To customize the header's colors, find the options showing below in Theme Editor:`}</p>
    <p><img alt="Theme editor header" src={require("../img/theme-editor-header.png")} /></p>
    <h2 {...{
      "id": "banner-below-header"
    }}>{`Banner below Header`}</h2>
    <p><img alt="banner below header" src={require("../img/home1-banner-below-header.png")} /></p>
    <p>{`You can enable this banner across all pages or on all other pages except Home Page in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` > `}<strong parentName="p">{`Banner after header`}</strong>{`:`}</p>
    <p><img alt="configure banner after header" src={require("../img/theme-editor-banner-after-header.png")} /></p>
    <p>{`After enabled, you can edit banner content by go to `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`, click `}<strong parentName="p">{`Create a Banner`}</strong>{` button. Input the fields as below:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Banner Name`}</strong>{`: whatever.`}</li>
      <li parentName="ul"><strong parentName="li">{`Banner Content`}</strong>{`: click the `}<strong parentName="li">{`HTML`}</strong>{` button to open `}<strong parentName="li">{`HTML Source Editor`}</strong>{`.`}</li>
    </ul>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <BannerBelowHeaderBuilderComponent mdxType="BannerBelowHeaderBuilderComponent" />
    <p>{`Remember to choose other fields as below:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{`: `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Visible`}</strong>{`: Checked`}</li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "3-offer-banners-below-the-header"
    }}>{`3 offer banners below the header`}</h2>
    <p><img alt="3-offer-banners-below-header" src={require("../img/3-offer-banners-below-header.png")} /></p>
    <p>{`Go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{` > `}<strong parentName="p">{`Banner after header`}</strong>{` > choose `}<strong parentName="p">{`Show on all pages`}</strong>{`.`}</p>
    <p>{`Create a new banner in `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`, In the content editor, click HTML button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <ThreeBannersBelowHeaderBuilderComponent mdxType="ThreeBannersBelowHeaderBuilderComponent" />
    <p>{`Choose other fields as below:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{`: `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Visible`}</strong>{`: Checked`}</li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      